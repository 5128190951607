import _omit from "lodash/omit";
import superjson from "superjson";
import { JSONValue } from "superjson/dist/types";

export function serialize(json: any): JSONValue {
  const sjson = superjson.serialize(json);
  const jsonWithMeta = sjson.json;
  if ("meta" in sjson) (jsonWithMeta as any)["_meta"] = sjson.meta;
  return jsonWithMeta;
}

export function deserialize(jsonWithMeta: any): JSONValue {
  const sjson: any = { json: _omit(jsonWithMeta, "_meta"), meta: jsonWithMeta._meta };
  return superjson.deserialize(sjson);
}
